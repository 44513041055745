import React, { Component } from 'react'
import Section from './Section'
import { TranslationConsumer } from './Translation'

import planetaLogo from '../images/planeta-logo.svg'
import appStoreButton from '../images/itunes-store-badge.png'
import playStoreButton from '../images/google-play-badge.png'
import './FooterSection.css'

/* global KlaviyoSubscribe */
/* global document */

class FooterSection extends Component {
  componentDidMount() {
    const script = window.document.createElement('script')
    script.src = 'https://www.klaviyo.com/media/js/public/klaviyo_subscribe.js'
    window.document.body.appendChild(script)

    setTimeout(() => {
      KlaviyoSubscribe.attachToForms('#email_signup', {
        //  hide_form_on_success: true,
        custom_success_message: 'Thanks for subscribing!',
        success_message: 'Thanks for subscribing!',
        extra_properties: {
          $source: '$embed',
          $method_type: 'Klaviyo Form',
          $method_id: 'embed',
          $consent_version: 'Embed default text',
        },
      })
    }, 2000)
  }

  render() {
    return (
      <TranslationConsumer>
        {({ t }) => (
          <Section className="FooterSection">
            <div className="FooterSection_main">
              <div className="FooterSection_title">{t('bottom_title')}</div>

              <div className="FooterSection_content">
                <div className="FooterSection_content-col1">
                  {t('bottom_col1_content')}
                </div>

                <div className="FooterSection_app-links">
                  <a
                    href="https://itunes.apple.com/us/app/david-bowie-is/id1447594485"
                    className="Footer_app-store-link"
                  >
                    <img src={appStoreButton} />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.planeta.BowieMobile"
                    className="Footer_play-store-link"
                  >
                    <img src={playStoreButton} />
                  </a>
                </div>
                <div className="FooterSection_content-col2">
                  {t('bottom_col2_content')}
                </div>
                <div className="FooterSection_content-col3">
                  {t('bottom_col3_content')}
                </div>
              </div>
            </div>

            <div className="Footer_mailing-list-signup">
              <div className="signup-form">
                <div className="signup-form-cta">{t('mailing_list_cta')}</div>
                <form
                  id="email_signup"
                  action="//manage.kmail-lists.com/subscriptions/subscribe"
                  data-ajax-submit="//manage.kmail-lists.com/ajax/subscriptions/subscribe"
                  method="GET"
                  target="_blank"
                  noValidate="novalidate"
                >
                  <input
                    type="hidden"
                    readOnly={true}
                    name="g"
                    value="KkMiWb"
                  />
                  <input
                    type="hidden"
                    readOnly={true}
                    name="$fields"
                    value="$consent"
                  />
                  <input
                    type="hidden"
                    readOnly={true}
                    name="$list_fields"
                    value="$consent"
                  />

                  <div className="klaviyo_field_group">
                    <input
                      className=""
                      type="email"
                      name="email"
                      id="k_id_email"
                      placeholder={t('mailing_list_email_placeholder')}
                    />
                    <div className="klaviyo_form_actions">
                      <input
                        type="submit"
                        value={t('mailing_list_subscribe')}
                      />
                    </div>
                  </div>

                  <div className="klaviyo_messages">
                    <div
                      className="success_message"
                      style={{ display: 'none' }}
                    />
                    <div
                      className="error_message"
                      style={{ display: 'none' }}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="FooterSection_links">
              <div className="FooterSection_terms-links">
                <a href={t('site_terms_url')}>{t('site_terms_label')}</a>
                <a href={t('app_terms_url')}>{t('app_terms_label')}</a>
              </div>
              <div className="FooterSection_attribution-link">
                <a target="_blank" href="https://planeta.cc">
                  <img src={planetaLogo} />
                </a>
              </div>
            </div>
          </Section>
        )}
      </TranslationConsumer>
    )
  }
}

export default FooterSection
